@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap);
body {
  font-family: 'Roboto', sans-serif; 
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 11vh;
}

a { 
  color: inherit; 
} 

a:hover { 
  color: inherit; 
} 

.nav-links {
  display: flex;
  justify-content: space-around;
  width: 20%;
}

.nav-links p{
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  margin: 0;
}

.nav-links-active:hover {
  color: red
}

.dropdown-toggle {
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  margin: 0;
}

.dropdown-menu {
  width: 200px;
  border-radius: 0;
  box-shadow: 2px 2px 15px 1px #00000025;
  margin: 0;
  padding: 0;
}

.dropdown-menu a{
  text-decoration: none;
}

.dropdown-item {
  font-size: 18px;
  font-weight: 500;
  padding: 15px 20px;
  border-bottom: 1px solid lightgray;
}

.dropdown-item:hover {
  background-color: #B2010D;
  color: white;
}

.logo img:nth-child(2) {
  display: none;
}

.your-store {
  padding: 0px 20px;
  border-left: 2px solid lightgray;
}

.your-store p:nth-child(1) {
  color: #E52A33;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.your-store p:nth-child(2) {
  font-size: 14px;
  font-weight: 500;
  margin: 0
}

.or {
  font-size: 16px;
  margin: 10px;
  color: #555;
}

.store-search input {
  border-radius: 50px;
  width: 100%;
  padding: 4px 20px;
  border: 1px solid #a5a5a5;
  color: rgb(0, 0, 0);
  font-size: 16px;
  outline: none;
}

.store-search input:focus {
  border: 1px solid #000000;
}

.zip-or-city {
  margin-top: 10px;
  color: #2a75d8;
  font-weight: 500;
  float: right;
  cursor: pointer;
}

.store-card { 
  display: flex;
  align-items: center;
}

.store-card p {
  margin: 0 10px;;
  color: #555;  
  font-size: 14px;
}

.store-hours p:nth-child(1) {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
}

.store-hours p:nth-child(2) {
  margin: 0;
  color: #555;  
  font-size: 14px;
}

.store-hours p:nth-child(3) {
  margin: 0;
  font-weight: bold;
  font-size: 13px;
}

.store-hours p:nth-child(4) {
  margin: 0;
  color: #555;  
  font-size: 14px;
}


.cart {
  padding: 6px 10px;
  border-left: 2px solid lightgray;
  text-align: center;
}

.cart-icon {
  font-size: 20px;
  margin-left: auto;
  position: relative;
}

.cart-icon-count {
  position: absolute;
  top: -7px;
  left: 20px;
  background-color: #E52A33;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: white;
  font-size: 14px;
}

.cart p {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.burger {
  display: none;
  cursor: pointer;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.side-nav {
  display: none;
}

.sidenav-top {
  background-color: #B2010D;
  color: white;
  padding: 10px 0;
}

.sidenav-top p {
  font-size: 16px;
}

.sidenav-links {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  margin: 0;
}

.sidenav-links p {
  color: white;
  list-style: none;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 20px;
  margin: 0;
  border-bottom: 1px solid #333333;
}

.sidenav-links p:hover {
  text-decoration: underline;
}

.sidenav-expanded p {
  color: white;
  list-style: none;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;  
  padding-left: 50px;
  margin: 0;
}

.sidenav-top p {
  margin: 0;
  font-size: 16px;
  text-transform: none;
  border: none;
}


.nav-divider {
  background-color: #131313;
  width: 100%;
  height: 45px;
  color: rgb(226, 226, 226);
}

.nav-divider p {
  display: inline-block;
  font-weight: 500;
  font-size: 18px;
  padding: 9px 0;
}

.nav-divider p:nth-child(2) {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  padding: 11px 0;
  color: #F8E080;
  cursor: pointer;
}

.divider-lower {
  background-color: #F8E080;
  width: 100%;
  height: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.671), 0 6px 20px 0 rgba(0, 0, 0, 0.445)
}

.home-deals {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-deals h1{
  font-weight: bold;
  font-size: 25px;
}

.home-deals p {
  margin: 0;
  font-weight: 500;
  color: #2a75d8;
}

.home-deals a:hover {
  text-decoration: none;
}

.home-deals-border {
  border: 1px solid rgb(196, 196, 196);
}

.home-deals-card {
  padding: 10px 10px;
}

.home-deals-card img {
  width: 100%;
  object-fit: cover;
}

.home-deals-card h5 {
  font-size: 16px;
  margin: 10px 0 0 10px;
}

.home-deals-card p {
  font-size: 14px;
  margin: 0 0 0 10px;
}

.home-menu-card {
  padding: 12px;
  position: relative;
}

.home-menu-card img {
  width: 100%;
}

.home-menu-card p {
  position: absolute;
  top: 20px;
  left: 30px;
  color: white;
  font-weight: 500;
  font-size: 25px;
}

.menu-page-nav {
  padding: 15px 10px;
  margin: 30px 0 5px 0; 
  border-top: 2px solid lightgray;
  border-bottom: 2px solid lightgray;
}

.menu-page-nav span {
  font-size: 16px;
  font-weight: 500;
  margin: 0 3%;
}

.deals_card_main {
  padding: 5px;
}

.deals_card {
  border: 1px solid lightgray;
  margin: 5px 5px;
  padding: 0 12px;
}

.popover {
  position: absolute;
  top: 8px !important;
  left: 60px !important;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.356), 0 6px 20px 0 rgba(0, 0, 0, 0.445)
}

.menu-size {
  margin: 10px 0px;
  border-radius: 20px;
  padding: 6px 16px;
  font-size: 14px;
  font-weight: 300;
  color: #555;
}

.menu-size:focus {
  outline: none;
}

.cal-info p {
  margin: 0px;
  color: #555;
}

.deal_image {
  height: 150px;
}

.deals_btn {
  margin-top: 110px;
}

.cart-page a:hover {
 text-decoration: none;
}
.carryOut-from {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.carryOut-from h6 {
  margin: 0;
}

.carryOut-from p {
  color: #555;
  font-size: 14px;
  margin: 0 0 0 10px;
}

.cart-summary {
  padding: 20px;
  border: 1px solid #d4d4d4;
  margin: 10px 0 20px 0;
}

.cart-items-title {
  display: flex;
}

.cart-items-title img{
  height: 120px;
}

.cart-item-select {
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid #d4d4d4;
  outline: none;
}

.remove-cart-item {
  color: #2a75d8;
  font-weight: 500;
  padding: 5px 0; 
  cursor: pointer;
}

.coupon-not-valid {
  color: #555;
  font-size: 14px;
}

.coupon-div {
  display: flex;
  justify-content: space-between;
}

.coupon-code {
  margin: 10px 0px;
  border-radius: 10px;
  padding: 8px 16px;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  color: rgb(0, 0, 0);
  border: 1px solid rgb(167, 167, 167);
  width: 70%;
  outline: none;
}

.coupon-code:focus {
  border: 1px solid rgb(0, 0, 0);
}

.cart-subtotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-subtotal p {
  margin: 0 0 4px 0;
  font-weight: 500;
}


.payment-methods input[type="radio"] {
  display: none;
}

.payment-methods input[type="radio"]+label {
  position: relative;
  padding: 0px 0 0 40px;
  cursor: pointer;
}

.payment-methods input[type="radio"]+label:before {
  content: '';
  background: #fff;
  border: 1px solid black;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.payment-methods input[type="radio"]+label:after {
  content: '';
  background: black;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 6px;
  opacity: 0;
}

.payment-methods input[type="radio"]:checked+label:before {
  border: 2px solid rgb(214, 0, 0);
}

.payment-methods input[type="radio"]:checked+label:after {
  opacity: 1;
}

.gift-code {
  margin: 10px 0px;
  border-radius: 10px;
  padding: 8px 16px;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  color: #000000;
  border: 1px solid rgb(167, 167, 167);
  width: 250px;
  outline: none;
}

.gift-code:focus {
  border: 1px solid rgb(0, 0, 0);
}

.gift-card-form p{
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.giftcard-not-valid {
  color: rgb(236, 0, 0);
  font-size: 12px;
  font-weight: 300;
  margin: 0; 
}

.card-details {
  margin: 10px 0px;
  border-radius: 10px;
  padding: 8px 16px;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  color: #000000;
  border: 1px solid rgb(167, 167, 167);
  width: 100%;
  outline: none;
}

.invalid-input {
  border: 1px solid red;
}

.card-details:focus {
  border: 1px solid rgb(0, 0, 0);
}

.app-footer {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  padding: 10px 10px;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.footer-links, .social-links {
  display: flex;
  align-items: center;
}

.footer-links p {
  margin: 0 10px;
  font-size: 12px;
  font-weight: 500;
}

.footer-links a {
  text-decoration: none;
}

.footer-links p:hover {
  color: #b2010d;
}

.footer-icons {
  text-align: center;
  background-color: #E52A33;
  color: white;
  margin: 0 5px;
  padding: 6px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
}

.footer-icons:hover {
  background-color: black;
}

@media screen and (max-width:768px) {

  body {
    overflow-x: hidden;
  }

  .nav-links {
    display: none;
  }

  .logo img:nth-child(2) {
    display: block;
  }

  .logo img:nth-child(1) {
    display: none;
  }

  .burger {
    display: block;
  }

  .side-nav {
    position: absolute;
    right: 0px;
    top: 11vh;
    height: 92vh;
    background-color: #131313;
    flex-direction: column;
    width: 50%;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition: -webkit-transform 0.5s ease-in;
    transition: -webkit-transform 0.5s ease-in;
    transition: transform 0.5s ease-in;
    transition: transform 0.5s ease-in, -webkit-transform 0.5s ease-in;
    z-index: 4;
  }
}

.sidenav-active {
  display: flex;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.burger-active .line1 {
  -webkit-transform: rotate(-45deg) translate(-5px,6px);
          transform: rotate(-45deg) translate(-5px,6px);
}

.burger-active .line2 {
  opacity: 0;
}                       

.burger-active .line3 {
  -webkit-transform: rotate(45deg) translate(-5px,-6px);
          transform: rotate(45deg) translate(-5px,-6px);
}
